import {Component, Input, Output, EventEmitter, AfterViewChecked} from '@angular/core';
import {ChatNachricht} from '../chatnachricht';
import {ChatService} from '../services/chat.service';
import {GespraechContainer} from '../generated/types';
import {ConferenceData} from '../services/conference.hub';
import {ConferenceDatas} from '../webrtc/conference2.component';

@Component({
    selector: 'chat-verlauf',
    templateUrl: './chatverlauf.component.html'
})
export class ChatVerlaufComponent implements AfterViewChecked {

    nachrichtenCount: number = 0;
    showIsWriting: boolean = false;
    timerHandle: number;
    showWritingTimeout: number = 2500; // ms
    data: ConferenceData | null = null;
    clientId: any;

    @Input() nachricht: string;
    @Input() currentUserToken: string;
    @Input() currentGespraech: GespraechContainer;
    @Input() currentWritingUserToken: string;
    @Input() nachrichten: ChatNachricht[] = [];
    @Input() forTeilnehmer: boolean = false;
    @Input() isNoAgent: boolean = false;
    @Input() visibleForTeilnehmer: boolean = false;

    @Output() send: EventEmitter<string> = new EventEmitter<string>();
    @Output() onWhisperAnswer: EventEmitter<string> = new EventEmitter<string>();

    ngAfterViewChecked() {
        if (this.nachrichtenCount !== this.nachrichten.length) {
            this.doScroll();
        }
        this.nachrichtenCount = this.nachrichten.length;
    }

    public setData(data: ConferenceDatas) {
        this.data = data.data;
        this.clientId = data.clientId;
        this.nachrichten = [...this.nachrichten];
    }

    getSenderStream(senderToken: string) {
        if (this.data && this.currentUserToken != senderToken) {
            let ret = Object.entries(this.data.streams).find(s => s[1].benutzerToken == senderToken);
            return ret ? ret[1] : null;
        }

        return null;
    }

    setShowIsWriting() {
        if (this.currentWritingUserToken && this.currentWritingUserToken !== this.currentUserToken) {
            this.showIsWriting = true;
            this.doScroll();
            if (this.timerHandle) {
                window.clearTimeout(this.timerHandle);
            }
            this.timerHandle = window.setTimeout(() => {
                this.showIsWriting = false
            }, this.showWritingTimeout);
        }
    }

    scrollTop(element, scrollHeight) {
        var scrollInterval = 15;
        var scrollDuration = 600;
        var scrollMaxStep = 50;
        var scrollStep = (element.scrollHeight - element.clientHeight) / (scrollDuration / scrollInterval);
        var step = 0;
        var scrInterval = setInterval(function () {
            if (element.scrollTop + element.clientHeight < scrollHeight && step < scrollMaxStep) {
                element.scrollTop = element.scrollTop + scrollStep;
            } else {
                clearInterval(scrInterval);
            }
            step++;
        }, scrollInterval);
    }

    doScroll() {
        var cv = document.getElementById('chatVerlauf');
        window.setTimeout(() => {
            this.scrollTop(cv, cv.scrollHeight)
        }, 150);
    }

    addSysteminfo(text: string) {
        this.send.next(text);
    }

    whisperAnswer(name: string) {
        this.onWhisperAnswer.next(name);
    }

}
