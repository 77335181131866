import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { Gespraech2TeilnehmerStartContainer } from "./types";
import { HttpParams, HttpRequest, HttpHeaders, HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class TeilnehmerHistoryHttpService {

  constructor(private httpService : HttpService) { }

  getTeilnehmerForConnection(gespraechsToken: string, connectionId: string) { 
    const params : any = {};
    if(gespraechsToken != null) { params['gespraechsToken'] = gespraechsToken; }
    if(connectionId != null) { params['connectionId'] = connectionId; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/teilnehmerHistory/GetTeilnehmerForConnection`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as Gespraech2TeilnehmerStartContainer } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
