

export interface FrageGesprachEndeModel {
  id?: number;
  text: string;
  bewertungstyp?: string;
  frage2Auswahl?: Frage2AuswahlModel[];
}

export const createFrageGesprachEndeModel = () => ({"id":0,"text":"","bewertungstyp":"Bewertungsskala","frage2Auswahl":[]});

export interface Frage2AuswahlModel {
  id?: number;
  frageId?: number;
  text?: string;
}

export const createFrage2AuswahlModel = () => ({"id":0,"frageId":0,"text":null});

export interface TupleOfBooleanAndString {
  item1?: boolean;
  item2?: string;
}

export interface BewertungContainer {
  bewertungen?: AntwortModel;
  fragen?: { [key: string]: string };
  bewertungstyp?: { [key: string]: string };
  text?: string;
  doBeweissicherung?: boolean;
}

export const createBewertungContainer = () => ({"bewertungen":null,"fragen":null,"bewertungstyp":null,"text":null,"doBeweissicherung":false});

export interface AntwortModel {
  rating?: number;
  bewertungText?: string;
  frageId?: number;
  frage2Auswahl?: SelectedFrageAuswahl[];
  bewertungstyp?: string;
}

export const createAntwortModel = () => ({"rating":0,"bewertungText":null,"frageId":0,"frage2Auswahl":null,"bewertungstyp":"Bewertungsskala"});

export interface SelectedFrageAuswahl {
  frage2Auswahl?: { [key: string]: boolean };
  frageAuswahlId?: number;
}

export const createSelectedFrageAuswahl = () => ({"frage2Auswahl":null,"frageAuswahlId":0});

export interface AngemeldeterAgentContainer {
  id?: number;
  name?: string;
  status?: number;
  isVideo?: boolean;
  anrufgruppen?: AnrufgruppeContainer[];
  isTextOnly?: boolean;
  videoEnabled?: boolean;
}

export const createAngemeldeterAgentContainer = () => ({"id":0,"name":null,"status":0,"isVideo":false,"anrufgruppen":null,"isTextOnly":false,"videoEnabled":false});

export interface AnrufgruppeContainer {
  id?: number;
  name?: string;
  uberlaufgruppeId?: string;
  keinChatprotokoll?: boolean;
  logo?: Base64FileModel;
  virtuellerHintergrund?: Base64FileModel;
}

export const createAnrufgruppeContainer = () => ({"id":0,"name":null,"uberlaufgruppeId":null,"keinChatprotokoll":false,"logo":null,"virtuellerHintergrund":null});

export interface Base64FileModel {
  id?: number;
  filename?: string;
  base64Data?: string;
}

export const createBase64FileModel = () => ({"id":0,"filename":"","base64Data":""});

export interface UploadSettingConfiguration {
  allowedFileExtensions?: string[];
  maxUploadSize?: number;
}

export const createUploadSettingConfiguration = () => ({"allowedFileExtensions":null,"maxUploadSize":0});

export interface Option {
  value: {};
  label: string;
}

export interface AnrufgruppeModel {
  name: string;
  user?: number[];
  servicezeiten?: ServicezeitenContainer;
  logo?: Base64FileModel;
  virtuellerHintergrund?: Base64FileModel;
  ueberlaufgruppeId?: number;
  timeoutAnruf?: number;
  styles?: SingleStyleModel[];
  includeNameValidator?: boolean;
  defaultAnruferName?: string;
  redirectToStart?: boolean;
  redirectUrl?: string;
  textOnlyEnabled?: boolean;
  audioVideoEnabled?: boolean;
  bundeslandId: number;
  terminbuchungTage: number;
  useFeiertage?: boolean;
  keinBeraterOnlineKeineAnrufe?: boolean;
  keinChatprotokoll?: boolean;
  terminSlotMin?: number;
  terminSlotFrequencyMin?: number;
  terminSlotSafeMin?: number;
  terminSafeInAdvance?: number;
}

export const createAnrufgruppeModel = () => ({"name":"","user":[],"servicezeiten":null,"logo":null,"virtuellerHintergrund":null,"ueberlaufgruppeId":null,"timeoutAnruf":0,"styles":null,"includeNameValidator":true,"defaultAnruferName":"","redirectToStart":false,"redirectUrl":"","textOnlyEnabled":false,"audioVideoEnabled":false,"bundeslandId":0,"terminbuchungTage":0,"useFeiertage":false,"keinBeraterOnlineKeineAnrufe":false,"keinChatprotokoll":false,"terminSlotMin":0,"terminSlotFrequencyMin":0,"terminSlotSafeMin":0,"terminSafeInAdvance":0});

export interface ServicezeitenContainer {
  servicezeiten?: ServicezeitTag[];
}

export const createServicezeitenContainer = () => ({"servicezeiten":null});

export interface SingleStyleModel {
  id?: number;
  typ?: number;
  name?: string;
  value?: string;
}

export const createSingleStyleModel = () => ({"id":0,"typ":0,"name":null,"value":null});

export interface ServicezeitTag {
  beratungAktiv?: boolean;
  dayOfWeek?: number;
  serviceZeiten?: Servicezeit[];
}

export const createServicezeitTag = () => ({"beratungAktiv":false,"dayOfWeek":0,"serviceZeiten":null});

export interface Servicezeit {
  beginn?: string;
  ende?: string;
}

export const createServicezeit = () => ({"beginn":null,"ende":null});

export interface AnrufgruppePageRequest {
  filter?: string;
  paging?: Paging;
  ordering?: Ordering;
}

export const createAnrufgruppePageRequest = () => ({"filter":"","paging":{"size":20,"index":1},"ordering":{"key":"Name","direction":0}});

export interface Paging {
  size: number;
  index: number;
}

export const createPaging = () => ({"size":20,"index":1});

export interface Ordering {
  key: string;
  direction: number;
}

export const createOrdering = () => ({"key":"","direction":0});

export interface PageOfAnrufgruppeItem {
  total: number;
  items: AnrufgruppeItem[];
}

export const createPageOfAnrufgruppeItem = () => ({"total":0,"items":[]});

export interface AnrufgruppeItem {
  id?: number;
  name: string;
}

export const createAnrufgruppeItem = () => ({"id":0,"name":""});

export interface GespraechContainer {
  id?: number;
  typ?: number;
  typText?: string;
  token?: string;
  status?: number;
  statusText?: string;
  angenommenAm?: string;
  beendetAm?: string;
  startAm?: string;
  anrufDauer?: number;
  anrufgruppeId?: number;
  anrufgruppe?: AnrufgruppeContainer;
  teilnehmer?: TeilnehmerContainer[];
  protokollblob?: BlobContainer;
  beweissicherung?: boolean;
  beweissicherungsBlob?: BlobContainer;
  referrer?: string;
  additionalInfo?: string;
  hasVideo?: boolean;
  screenSharingAllowedFor?: number;
  isConference?: boolean;
  teilnehmerCount?: number;
}

export const createGespraechContainer = () => ({"id":0,"typ":0,"typText":null,"token":null,"status":0,"statusText":null,"angenommenAm":null,"beendetAm":null,"startAm":null,"anrufDauer":null,"anrufgruppeId":null,"anrufgruppe":null,"teilnehmer":null,"protokollblob":null,"beweissicherung":false,"beweissicherungsBlob":null,"referrer":null,"additionalInfo":null,"hasVideo":false,"screenSharingAllowedFor":0,"isConference":false,"teilnehmerCount":0});

export interface TeilnehmerContainer {
  userId?: number;
  teilnehmerId?: number;
  name?: string;
  status?: number;
  arbeitsplatzausstattung?: number;
  benutzerToken?: string;
  connectionId?: string;
}

export const createTeilnehmerContainer = () => ({"userId":null,"teilnehmerId":null,"name":null,"status":0,"arbeitsplatzausstattung":0,"benutzerToken":null,"connectionId":null});

export interface BlobContainer {
  id?: number;
  filename?: string;
  filesize?: number;
}

export const createBlobContainer = () => ({"id":0,"filename":null,"filesize":0});

export interface ArbeitszeitenContainer {
  items?: ArbeitszeitItem[];
}

export const createArbeitszeitenContainer = () => ({"items":null});

export interface ArbeitszeitItem {
  id?: number;
  dayOfTheWeek?: number;
  beginn?: string;
  ende?: string;
  markAsDeleted?: boolean;
}

export const createArbeitszeitItem = () => ({"id":0,"dayOfTheWeek":0,"beginn":null,"ende":null,"markAsDeleted":false});

export interface BesetztzeitContainer {
  items?: BesetztzeitItem[];
}

export const createBesetztzeitContainer = () => ({"items":null});

export interface BesetztzeitItem {
  id?: number;
  beginn?: string;
  ende?: string;
  markAsDeleted?: boolean;
}

export const createBesetztzeitItem = () => ({"id":0,"beginn":"0001-01-01T00:00:00Z","ende":"0001-01-01T00:00:00Z","markAsDeleted":false});

export interface ConferenceCreateModel {
  betreff?: string;
  anrufgruppeId?: number;
  startzeit?: string;
  organisator?: ConferenceTeilnehmerCreateModel;
  teilnehmer?: ConferenceTeilnehmerCreateModel[];
  dauer?: number;
}

export const createConferenceCreateModel = () => ({"betreff":null,"anrufgruppeId":0,"startzeit":"0001-01-01T00:00:00Z","organisator":null,"teilnehmer":null,"dauer":0});

export interface ConferenceTeilnehmerCreateModel {
  email?: string;
  anrede?: string;
  name?: string;
  rechtevorlageId: number;
}

export const createConferenceTeilnehmerCreateModel = () => ({"email":null,"anrede":null,"name":null,"rechtevorlageId":null});

export interface ConferenceStartModel {
  conferenceStartInfo?: ConferenceStartInfoModel;
  teilnehmer?: ConferenceTeilnehmerModel;
}

export const createConferenceStartModel = () => ({"conferenceStartInfo":null,"teilnehmer":null});

export interface ConferenceStartInfoModel {
  start?: string;
  betreff?: string;
  organisatorName?: string;
  conferenceClosed?: boolean;
  token?: string;
  hasStarted?: boolean;
}

export const createConferenceStartInfoModel = () => ({"start":"0001-01-01T00:00:00Z","betreff":null,"organisatorName":null,"conferenceClosed":false,"token":null,"hasStarted":false});

export interface ConferenceTeilnehmerModel {
  email?: string;
  anrede?: string;
  name?: string;
  benutzerToken?: string;
  isOrganizer?: boolean;
  rechtevorlage?: RechtevorlageModel;
}

export const createConferenceTeilnehmerModel = () => ({"email":null,"anrede":null,"name":null,"benutzerToken":null,"isOrganizer":false,"rechtevorlage":null});

export interface RechtevorlageModel {
  id?: number;
  initialVideoActive?: boolean;
  canActivateVideo?: boolean;
  initialAudioActive?: boolean;
  canActivateAudio?: boolean;
  canStartScreensharing?: boolean;
  isManager?: boolean;
  visibleForTeilnehmer?: boolean;
  isAnonymousForOthers?: boolean;
}

export const createRechtevorlageModel = () => ({"id":0,"initialVideoActive":false,"canActivateVideo":false,"initialAudioActive":false,"canActivateAudio":false,"canStartScreensharing":false,"isManager":false,"visibleForTeilnehmer":false,"isAnonymousForOthers":false});

export interface ConfigurationContainer {
  textOnlyEnabled?: boolean;
  audioVideoEnabled?: boolean;
  allowChatCleanup?: boolean;
  lizenz?: LizenzContainer;
  logoBackendUrl?: string;
  allowedToAddMembers?: boolean;
  maxAllowedConferenceMembers?: number;
  enableTeilnehmerAktionStatistik?: boolean;
  verpassteAnrufeAusblenden?: boolean;
  youSignApiWerte?: boolean;
  allowVirtualBackground?: boolean;
  timeout?: number;
  terminSlotMin?: number;
  terminSlotFrequencyMin?: number;
  terminSlotSafeMin?: number;
  terminSafeInAdvance?: number;
}

export const createConfigurationContainer = () => ({"textOnlyEnabled":false,"audioVideoEnabled":false,"allowChatCleanup":false,"lizenz":null,"logoBackendUrl":null,"allowedToAddMembers":false,"maxAllowedConferenceMembers":0,"enableTeilnehmerAktionStatistik":false,"verpassteAnrufeAusblenden":false,"youSignApiWerte":false,"allowVirtualBackground":false,"timeout":0,"terminSlotMin":0,"terminSlotFrequencyMin":0,"terminSlotSafeMin":0,"terminSafeInAdvance":0});

export interface LizenzContainer {
  modules?: Module[];
  maxVerfuegbarAgents?: number;
  eSignatur?: boolean;
}

export const createLizenzContainer = () => ({"modules":null,"maxVerfuegbarAgents":0,"eSignatur":false});

export interface Module {
  name?: string;
  link?: string;
  rights?: string[];
  menutext?: string;
  active?: boolean;
  imgurl?: string;
}

export const createModule = () => ({"name":null,"link":null,"rights":null,"menutext":null,"active":false,"imgurl":null});

export interface AllowedLoginTimeFramesContainer {
  beforeConference?: number;
  afterConference?: number;
}

export const createAllowedLoginTimeFramesContainer = () => ({"beforeConference":0,"afterConference":0});

export interface DokumentPageRequest {
  filter?: DokumentFilter;
  paging?: Paging;
  ordering?: Ordering;
}

export const createDokumentPageRequest = () => ({"filter":{"searchText":"","anrufgruppeId":null},"paging":{"size":20,"index":1},"ordering":{"key":"name","direction":0}});

export interface DokumentFilter {
  searchText?: string;
  anrufgruppeId?: number;
}

export const createDokumentFilter = () => ({"searchText":"","anrufgruppeId":null});

export interface PageOfDokumentItem {
  total: number;
  items: DokumentItem[];
}

export const createPageOfDokumentItem = () => ({"total":0,"items":[]});

export interface DokumentItem {
  id?: number;
  file?: Base64FileModel;
  name?: string;
  beschreibung?: string;
  aktiv?: boolean;
  youSignId?: string;
}

export const createDokumentItem = () => ({"id":0,"file":null,"name":null,"beschreibung":null,"aktiv":false,"youSignId":null});

export interface DokumentContainer {
  file: Base64FileModel;
  name: string;
  beschreibung?: string;
  isAktiv?: boolean;
  anrufgruppeId?: number;
  url?: string;
  signatureId?: string;
  text?: string;
}

export const createDokumentContainer = () => ({"file":null,"name":null,"beschreibung":"","isAktiv":false,"anrufgruppeId":null,"url":null,"signatureId":null,"text":null});

export interface DownloadRequestContainer {
  token?: string;
  blobId?: number;
  benutzerToken?: string;
}

export const createDownloadRequestContainer = () => ({"token":null,"blobId":0,"benutzerToken":null});

export interface EinladungRequestContainer {
  eMail?: string;
  anrede?: string;
  name?: string;
  zeitpunkt?: string;
  zeitpunktEnde?: string;
  dauer?: number;
  benutzerToken?: string;
  benutzerLoginToken?: string;
  anrufgruppeId?: number;
  anrufgruppeEnabled?: boolean;
  callbackUrl?: string;
  teilnehmerId?: number;
  externKennungToken?: string;
}

export const createEinladungRequestContainer = () => ({"eMail":null,"anrede":null,"name":null,"zeitpunkt":"0001-01-01T00:00:00Z","zeitpunktEnde":null,"dauer":0,"benutzerToken":null,"benutzerLoginToken":null,"anrufgruppeId":0,"anrufgruppeEnabled":false,"callbackUrl":null,"teilnehmerId":null,"externKennungToken":null});

export interface EinladungItem {
  id?: number;
  zeitpunkt?: string;
  gueltigAb?: string;
  gueltigBis?: string;
  dauer?: number;
  agent?: string;
  name?: string;
  eMail?: string;
  anrufgruppeId?: number;
  anrufgruppeEnabled?: boolean;
  forConference?: boolean;
  token?: string;
  teilnehmerId?: number;
  anrede?: string;
  canEditAndDelete?: boolean;
  ersteller?: string;
}

export const createEinladungItem = () => ({"id":0,"zeitpunkt":"0001-01-01T00:00:00Z","gueltigAb":"0001-01-01T00:00:00Z","gueltigBis":"0001-01-01T00:00:00Z","dauer":0,"agent":null,"name":null,"eMail":null,"anrufgruppeId":0,"anrufgruppeEnabled":false,"forConference":false,"token":null,"teilnehmerId":null,"anrede":null,"canEditAndDelete":false,"ersteller":null});

export interface EinladungContainer {
  agent?: string;
  name?: string;
  isValid?: boolean;
  reason?: string;
  logo?: Base64FileModel;
  anrufgruppe?: string;
}

export const createEinladungContainer = () => ({"agent":null,"name":null,"isValid":false,"reason":null,"logo":null,"anrufgruppe":null});

export interface EinladungTeilnehmerContainer {
  id?: number;
  name?: string;
}

export const createEinladungTeilnehmerContainer = () => ({"id":0,"name":null});

export interface ExternEinladungDto {
  emailOrganistor?: string;
  anredeKunde?: string;
  emailKunde?: string;
  nameKunde?: string;
  zeitpunktBeginn?: string;
  zeitpunktEnde?: string;
  einladungId?: number;
  anrufgruppeEnabled?: boolean;
  callbackUrl?: string;
  benutzerLoginToken?: string;
  externKennungToken?: string;
  anrufgruppe?: string;
}

export const createExternEinladungDto = () => ({"emailOrganistor":null,"anredeKunde":null,"emailKunde":null,"nameKunde":null,"zeitpunktBeginn":null,"zeitpunktEnde":null,"einladungId":0,"anrufgruppeEnabled":false,"callbackUrl":null,"benutzerLoginToken":null,"externKennungToken":null,"anrufgruppe":null});

export interface EinladungAbsagenDto {
  emailOrganistor?: string;
  einladungId?: number;
}

export const createEinladungAbsagenDto = () => ({"emailOrganistor":null,"einladungId":0});

export interface EinladungVerschiebenDto {
  emailOrganistor?: string;
  zeitpunktBeginn?: string;
  zeitpunktEnde?: string;
  einladungId?: number;
}

export const createEinladungVerschiebenDto = () => ({"emailOrganistor":null,"zeitpunktBeginn":"0001-01-01T00:00:00Z","zeitpunktEnde":"0001-01-01T00:00:00Z","einladungId":0});

export interface CheckUserRequest {
  email?: string;
  anrufgruppe?: string;
}

export const createCheckUserRequest = () => ({"email":null,"anrufgruppe":null});

export interface CreateUserRequest {
  email?: string;
  nachname?: string;
  vorname?: string;
  anrede?: string;
  login?: string;
  anrufgruppe?: string;
}

export const createCreateUserRequest = () => ({"email":null,"nachname":null,"vorname":null,"anrede":null,"login":null,"anrufgruppe":null});

export interface FrageModel {
  text: string;
  typ: number;
  anrufgruppeId?: number;
  gespraechsStatus: number;
  chatTyp: number;
  aktiv?: boolean;
  bewertungstyp?: string;
  frage2Auswahl?: Frage2AuswahlModel[];
}

export const createFrageModel = () => ({"text":"","typ":null,"anrufgruppeId":null,"gespraechsStatus":null,"chatTyp":null,"aktiv":false,"bewertungstyp":"Bewertungsskala","frage2Auswahl":[]});

export interface FragePageRequest {
  filter?: FrageFilter;
  paging?: Paging;
  ordering?: Ordering;
}

export const createFragePageRequest = () => ({"filter":{"filter":"","anrufgruppeId":null,"typ":null},"paging":{"size":20,"index":1},"ordering":{"key":"Text","direction":0}});

export interface FrageFilter {
  filter?: string;
  anrufgruppeId?: number;
  typ?: number;
}

export const createFrageFilter = () => ({"filter":"","anrufgruppeId":null,"typ":null});

export interface PageOfFrageItem {
  total: number;
  items: FrageItem[];
}

export const createPageOfFrageItem = () => ({"total":0,"items":[]});

export interface FrageItem {
  id?: number;
  text: string;
  typ: number;
  aktiv?: boolean;
  gespraechsStatus: number;
  relatedFragenCount?: number;
  bewertungstyp?: string;
  frage2Auswahl?: Frage2AuswahlModel[];
}

export const createFrageItem = () => ({"id":0,"text":"","typ":null,"aktiv":false,"gespraechsStatus":null,"relatedFragenCount":0,"bewertungstyp":"Bewertungsskala","frage2Auswahl":[]});

export interface GespraechsSettingsContainer {
  uploadDisabled?: boolean;
  emojiDisabled?: boolean;
  isAgent?: boolean;
}

export const createGespraechsSettingsContainer = () => ({"uploadDisabled":false,"emojiDisabled":false,"isAgent":false});

export interface KonferenzdauerContainer {
  id?: string;
  minuten?: number;
  text?: string;
}

export const createKonferenzdauerContainer = () => ({"id":null,"minuten":0,"text":null});

export interface PasswordRequest {
  password?: string;
  passwordConfirmation?: string;
}

export const createPasswordRequest = () => ({"password":null,"passwordConfirmation":null});

export interface DefaultNachrichtenContainerRequest {
  anrufgruppeId?: number;
  typ?: number;
}

export const createDefaultNachrichtenContainerRequest = () => ({"anrufgruppeId":null,"typ":null});

export interface NachrichtenTemplateContainer {
  name: string;
  text: string;
  isAktiv?: boolean;
  typ: number;
  anrufgruppeId?: number;
}

export const createNachrichtenTemplateContainer = () => ({"name":"","text":null,"isAktiv":false,"typ":0,"anrufgruppeId":null});

export interface NachrichtenTemplatePageRequest {
  filter?: NachrichtenTemplateFilter;
  paging?: Paging;
  ordering?: Ordering;
}

export const createNachrichtenTemplatePageRequest = () => ({"filter":{"searchText":"","anrufgruppeId":null,"typen":[]},"paging":{"size":20,"index":1},"ordering":{"key":"name","direction":0}});

export interface NachrichtenTemplateFilter {
  searchText?: string;
  anrufgruppeId?: number;
  typen?: number[];
}

export const createNachrichtenTemplateFilter = () => ({"searchText":"","anrufgruppeId":null,"typen":[]});

export interface PageOfNachrichtenTemplateItem {
  total: number;
  items: NachrichtenTemplateItem[];
}

export const createPageOfNachrichtenTemplateItem = () => ({"total":0,"items":[]});

export interface NachrichtenTemplateItem {
  id?: number;
  name?: string;
  text?: string;
  typ?: number;
  aktiv?: boolean;
}

export const createNachrichtenTemplateItem = () => ({"id":0,"name":null,"text":null,"typ":0,"aktiv":false});

export interface RueckrufgesuchRequestContainer {
  anrufgruppeId?: number;
  benutzerToken?: string;
  nummer?: string;
  name?: string;
  eMail?: string;
  extra?: string;
}

export const createRueckrufgesuchRequestContainer = () => ({"anrufgruppeId":0,"benutzerToken":null,"nummer":null,"name":null,"eMail":null,"extra":null});

export interface BadRequestModel {
  message?: string;
  modelState?: { [key: string]: string[] };
}

export const createBadRequestModel = () => ({"message":null,"modelState":null});

export interface RueckrufgesuchContainer {
  id?: string;
  anrufgruppeId?: number;
  anrufgruppeName?: string;
  name?: string;
  nummer?: string;
  status?: number;
  statusText?: string;
  eMail?: string;
  extra?: string;
  createdAt?: string;
}

export const createRueckrufgesuchContainer = () => ({"id":null,"anrufgruppeId":null,"anrufgruppeName":null,"name":null,"nummer":null,"status":0,"statusText":null,"eMail":null,"extra":null,"createdAt":"0001-01-01T00:00:00Z"});

export interface ManagerSetting {
  systemStatus: string;
}

export const createManagerSetting = () => ({"systemStatus":"Normalbetrieb"});

export interface SettingPageRequest {
  filter?: string;
  paging?: Paging;
  ordering?: Ordering;
}

export const createSettingPageRequest = () => ({"filter":"","paging":{"size":20,"index":1},"ordering":{"key":"Name","direction":0}});

export interface PageOfSettingItem {
  total: number;
  items: SettingItem[];
}

export const createPageOfSettingItem = () => ({"total":0,"items":[]});

export interface SettingItem {
  id?: number;
  name?: string;
  value?: string;
}

export const createSettingItem = () => ({"id":0,"name":null,"value":null});

export interface SettingModel {
  name: string;
  value?: string;
}

export const createSettingModel = () => ({"name":"","value":null});

export interface StyleEditModel {
  styles?: SingleStyleModel[];
}

export const createStyleEditModel = () => ({"styles":null});

export interface ChatContainer {
  cameraEnabled?: boolean;
  textOnlyEnabled?: boolean;
  audioVideoEnabled?: boolean;
}

export const createChatContainer = () => ({"cameraEnabled":false,"textOnlyEnabled":false,"audioVideoEnabled":false});

export interface RedirectToStartModel {
  redirectToStart?: boolean;
  redirectUrl?: string;
}

export const createRedirectToStartModel = () => ({"redirectToStart":false,"redirectUrl":null});

export interface TeilnehmerSettings {
  video?: boolean;
  audio?: boolean;
  videoAllowed?: boolean;
  audioAllowed?: boolean;
  screensharingAllowed?: boolean;
}

export const createTeilnehmerSettings = () => ({"video":false,"audio":false,"videoAllowed":false,"audioAllowed":false,"screensharingAllowed":false});

export interface TeilnehmerWithTokenContainer {
  benutzerToken?: string;
  name?: string;
  status?: number;
}

export const createTeilnehmerWithTokenContainer = () => ({"benutzerToken":null,"name":null,"status":0});

export interface BeratungszeitContainer {
  beratungAktiv?: boolean;
  isInBeratungszeit?: boolean;
  keinBeraterOnlineKeineAnrufe?: boolean;
  beratungszeiten?: ServicezeitTag[];
  timeoutzeitInSekunden?: number;
  systemStatus?: SystemStatusContainer;
  anrufHeaderTitle?: string;
  anrufHeaderInfo?: string;
  servicezeitenTitle?: string;
  servicezeitenInfo?: string;
  hinweistext?: string;
  nutzungsbedingungen?: string;
  datenschutzUrl?: string;
  keinBeraterVerfuegbar?: string;
  impressumUrl?: string;
  providerText?: string;
  providerUrl?: string;
  servicezeiten?: ServicezeitenContainer;
  logo?: Base64FileModel;
  systemInformationen?: { [key: string]: string };
  textOnlyEnabled?: boolean;
  audioVideoEnabled?: boolean;
  anrufgruppeId?: number;
  defaultAnruferName?: string;
}

export const createBeratungszeitContainer = () => ({"beratungAktiv":false,"isInBeratungszeit":false,"keinBeraterOnlineKeineAnrufe":false,"beratungszeiten":null,"timeoutzeitInSekunden":0,"systemStatus":null,"anrufHeaderTitle":null,"anrufHeaderInfo":null,"servicezeitenTitle":null,"servicezeitenInfo":null,"hinweistext":null,"nutzungsbedingungen":null,"datenschutzUrl":null,"keinBeraterVerfuegbar":null,"impressumUrl":null,"providerText":null,"providerUrl":null,"servicezeiten":null,"logo":null,"systemInformationen":null,"textOnlyEnabled":false,"audioVideoEnabled":false,"anrufgruppeId":0,"defaultAnruferName":null});

export interface SystemStatusContainer {
  status?: number;
  statusText?: string;
  dateTime?: string;
  buildInfo?: string;
}

export const createSystemStatusContainer = () => ({"status":0,"statusText":null,"dateTime":null,"buildInfo":null});

export interface Gespraech2TeilnehmerStartContainer {
  gespraechsToken?: string;
  gespraechAngenommenAn?: string;
  connectionGespraechAnnahme?: string;
  teilnahmeVon?: string;
  isTeilnehmerKonferenzStart?: boolean;
  isSessionAnrufAnnahme?: boolean;
}

export const createGespraech2TeilnehmerStartContainer = () => ({"gespraechsToken":null,"gespraechAngenommenAn":"0001-01-01T00:00:00Z","connectionGespraechAnnahme":null,"teilnahmeVon":"0001-01-01T00:00:00Z","isTeilnehmerKonferenzStart":false,"isSessionAnrufAnnahme":false});

export interface TerminbuchungInfoRequest {
  anrufgruppeId?: number;
  anrufgruppe?: string;
}

export const createTerminbuchungInfoRequest = () => ({"anrufgruppeId":null,"anrufgruppe":null});

export interface TerminbuchungContainer {
  beratungszeit?: BeratungszeitContainer;
  logo?: Base64FileModel;
  anrufgruppeId?: number;
}

export const createTerminbuchungContainer = () => ({"beratungszeit":null,"logo":null,"anrufgruppeId":0});

export interface TerminbuchungSlotsRequest {
  anrufgruppeId?: number;
  anrufgruppe?: string;
  from?: string;
  until?: string;
}

export const createTerminbuchungSlotsRequest = () => ({"anrufgruppeId":null,"anrufgruppe":null,"from":"0001-01-01T00:00:00Z","until":"0001-01-01T00:00:00Z"});

export interface TerminbuchungSlotsContainer {
  slots?: TerminbuchungSlot[];
  slotsMin?: string;
  slotsMax?: string;
}

export const createTerminbuchungSlotsContainer = () => ({"slots":null,"slotsMin":null,"slotsMax":null});

export interface TerminbuchungSlot {
  from?: string;
  until?: string;
  agents?: number[];
}

export const createTerminbuchungSlot = () => ({"from":"0001-01-01T00:00:00Z","until":"0001-01-01T00:00:00Z","agents":null});

export interface TerminbuchungRequest {
  anrufgruppeId?: number;
  anrufgruppe?: string;
  agents?: number[];
  date?: string;
  anrede?: string;
  name?: string;
  empfaenger?: string;
}

export const createTerminbuchungRequest = () => ({"anrufgruppeId":null,"anrufgruppe":null,"agents":null,"date":"0001-01-01T00:00:00Z","anrede":null,"name":null,"empfaenger":null});

export interface ExtendedUserRequest {
  user?: UserRequest;
  anrufgruppeIds?: number[];
  anrufgruppe4ManagerIds?: number[];
  isTextchatOnly?: boolean;
}

export const createExtendedUserRequest = () => ({"user":{"passwordChange":{"password":null,"passwordConfirmation":null},"name":null,"eMail":null,"anrede":null,"vorname":null,"nachname":null,"isActivated":false,"lockedFrom":null,"isAdUser":false,"culture":"de-DE","groupIds":[],"activateUserTokenStatus":0,"items":[],"forceChangePassword":null},"anrufgruppeIds":[],"anrufgruppe4ManagerIds":[],"isTextchatOnly":false});

export interface UserRequest {
  passwordChange: PasswordRequest;
  name?: string;
  eMail?: string;
  anrede?: string;
  vorname?: string;
  nachname?: string;
  isActivated?: boolean;
  lockedFrom?: string;
  isAdUser?: boolean;
  culture?: string;
  groupIds: number[];
  activateUserTokenStatus?: number;
  items?: ItemResponse[];
  forceChangePassword?: boolean;
}

export const createUserRequest = () => ({"passwordChange":{"password":null,"passwordConfirmation":null},"name":null,"eMail":null,"anrede":null,"vorname":null,"nachname":null,"isActivated":false,"lockedFrom":null,"isAdUser":false,"culture":"de-DE","groupIds":[],"activateUserTokenStatus":0,"items":[],"forceChangePassword":null});

export interface ItemResponse {
  key?: string;
  value?: string;
}

export const createItemResponse = () => ({"key":null,"value":null});

export interface UserPageRequest {
  filter: UserFilter;
  paging: Paging;
  ordering: Ordering;
}

export const createUserPageRequest = () => ({"filter":{"search":"","groups":[],"isActivated":null,"additionalSQL":""},"paging":{"size":20,"index":1},"ordering":{"key":"nachname","direction":0}});

export interface UserFilter {
  search?: string;
  groups?: number[];
  isActivated?: boolean;
  additionalSQL?: string;
}

export const createUserFilter = () => ({"search":"","groups":[],"isActivated":null,"additionalSQL":""});

export interface PageOfUserItem {
  total: number;
  items: UserItem[];
}

export const createPageOfUserItem = () => ({"total":0,"items":[]});

export interface UserItem {
  id?: number;
  name?: string;
  eMail?: string;
  anrede?: string;
  vorname?: string;
  nachname?: string;
  isActivated?: boolean;
  lockedFrom?: string;
  isAdUser?: boolean;
  culture?: string;
  groupIds: number[];
  activateUserTokenStatus?: number;
  items?: ItemResponse[];
  forceChangePassword?: boolean;
}

export const createUserItem = () => ({"id":0,"name":null,"eMail":null,"anrede":null,"vorname":null,"nachname":null,"isActivated":false,"lockedFrom":null,"isAdUser":false,"culture":"de-DE","groupIds":[],"activateUserTokenStatus":0,"items":[],"forceChangePassword":null});

export interface ExtendedUserResponse {
  user?: UserResponse;
  anrufgruppeIds?: number[];
  anrufgruppe4ManagerIds?: number[];
  isTextchatOnly?: boolean;
}

export const createExtendedUserResponse = () => ({"user":{"name":null,"eMail":null,"anrede":null,"vorname":null,"nachname":null,"isActivated":false,"lockedFrom":null,"isAdUser":false,"culture":"de-DE","groupIds":[],"activateUserTokenStatus":0,"items":[],"forceChangePassword":null},"anrufgruppeIds":[],"anrufgruppe4ManagerIds":[],"isTextchatOnly":false});

export interface UserResponse {
  name?: string;
  eMail?: string;
  anrede?: string;
  vorname?: string;
  nachname?: string;
  isActivated?: boolean;
  lockedFrom?: string;
  isAdUser?: boolean;
  culture?: string;
  groupIds: number[];
  activateUserTokenStatus?: number;
  items?: ItemResponse[];
  forceChangePassword?: boolean;
}

export const createUserResponse = () => ({"name":null,"eMail":null,"anrede":null,"vorname":null,"nachname":null,"isActivated":false,"lockedFrom":null,"isAdUser":false,"culture":"de-DE","groupIds":[],"activateUserTokenStatus":0,"items":[],"forceChangePassword":null});

export interface TeilnehmerInfoContainer {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
}

export const createTeilnehmerInfoContainer = () => ({"firstName":null,"lastName":null,"email":null,"phone":null});
