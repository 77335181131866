import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from '@angular/forms';
import { LogService } from '@oevermann/angular';
import { LogLevel } from '@oevermann/core';
import { Subscription } from 'rxjs';
import { AUDIOSETTINGS, CAMERA_BACKGORUND_BLUR_SETTING, SPEAKERSETTINGS, VIDEOSETTINGS } from '../consts';
import { WebRtcClientConfiguration } from '../services/signalr.service';
import { StorageService } from '../services/storage.service';
import { AnsweringStreamerConnectionMultiplexer } from '../webrtc/AnsweringStreamerConnectionMultiplexer';
import { addOrReplaceTrack, getAudioTrackConstraints, getAudioTrackFromStream, getVideoTrackConstraints, getVideoTrackFromStream, stopStream } from '../webrtc/helpers';
import { LocalUserStream } from '../webrtc/LocalUserStream';
import { OfferingStreamerConnection } from '../webrtc/OfferingStreamerConnection';
import { WebRtcService } from '../webrtc/webrtc.service';
import { NotificationService } from './notification.service';
import { BaseComponent } from '../shared/base.component';
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { ConfigurationContainer } from "../generated/types";


@Component({
  selector: 'mediasettings',
  templateUrl: './mediasettings.component.html'
})
export class MediaSettingsComponent extends BaseComponent implements AfterViewInit, OnInit, OnDestroy {

  public camera_deviceId: FormControl = new FormControl('');
  public camera_backgroundBlur: FormControl = new FormControl(false);
  public microphone_deviceId: FormControl = new FormControl('');
  public microphone_autoGainControl: FormControl = new FormControl(false);
  public microphone_echoCancellation: FormControl = new FormControl(false);
  public microphone_noiseSupression: FormControl = new FormControl(false);
  public speaker_deviceId: FormControl = new FormControl('');
  public hasSinkId: boolean;
  private lists = [];
  private configuration: WebRtcClientConfiguration;
  media_message: string[] = [];

  // private subscription = new Subscription();

  private _localUserStream: LocalUserStream | null;

  private _token: string;
  get token(): string | null {
    return this._token;
  }
  @Input() set token(value: string | null) {
    let tokenChanged = false;
    if (this._token != value) {
      tokenChanged = true;
    }
    this._token = value;
    if (tokenChanged) {
      this.getConfiguration();
    }
  }
  @Input() connection: OfferingStreamerConnection | AnsweringStreamerConnectionMultiplexer;
  @Input() set localUserStream(value: LocalUserStream | null) {
    this._localUserStream = value;
    this.getConnectedDevices();
    if (this._localUserStream) {
      this.subscribeToTrackChanges();
    } else {
      console.log("mediasettings NO localUserStream");
      this.subscription.unsubscribe();
    }
  }

  get localUserStream(): LocalUserStream | null {
    return this._localUserStream;
  }

  private _previewStream: MediaStream | null;
  @Input() set previewStream(value: MediaStream | null) {
    this._previewStream = value;
    this.presetSettings();
  }

  get previewStream() {
    return this._previewStream;
  }

  @Input() closeButton: boolean;

  @Input() appConfiguration: ConfigurationContainer;

  @ViewChild('mediaSettings') mediaSettings: ElementRef;

  @Output() backgorundBlurChanged = new EventEmitter<boolean>();

  constructor(private notificationService: NotificationService,
    private webRtcService: WebRtcService,
    private logService: LogService,
    private storageService: StorageService) {
    super();
  }

  ngOnInit(): void {
    this.camera_backgroundBlur.setValue((this.storageService.getItem(CAMERA_BACKGORUND_BLUR_SETTING) ?? 'false').toLowerCase() == 'true');
    this.subscribeToTrackChanges();
  }

  ngOnDestroy(): void {
    // console.log("mediasettings unsubscribe");
    this.subscription.unsubscribe();
  }

  get isAllowedToActivateVirtualBackground() {
    return this.appConfiguration?.allowVirtualBackground ?? false;
  }

  private subscribeToTrackChanges() {
    this.subscription.unsubscribe();
    this.subscription = new Subscription();
    if (this._localUserStream) {
      this.LogInConferenceVariable('subcription mediasettings _localUserStream.id ', this._localUserStream.id);
      this.LogInConferenceVariable('mediasettings localUserStreamId ', this._localUserStream);

      this.subscription.add(this.localUserStream.audioTrack.subscribe((track) => {
        this.LogInConferenceVariable('subcription mediasettings new audio track', track);
        this.presetSettings();
      }));
      this.subscription.add(this.localUserStream.originalVideoTrack.subscribe((track) => {
        this.LogInConferenceVariable('subcription mediasettings new video track', track);
        this.presetSettings();
      }));
    }
  }

  async ngAfterViewInit() {
    await this.getConfiguration();
    await this.getConnectedDevices();
    this.presetSettings();

    if (this.supportsDeviceChangeEvent()) {
      navigator.mediaDevices.addEventListener('devicechange', async event => {
        await this.getConfiguration();
        await this.getConnectedDevices();
      });
    } else if (navigator.mediaDevices && typeof navigator.mediaDevices.ondevicechange !== 'undefined') {
      navigator.mediaDevices.ondevicechange = async event => {
        await this.getConfiguration();
        await this.getConnectedDevices();
      };
    }

  }

  supportsDeviceChangeEvent() {
    return navigator.mediaDevices
      && typeof navigator.mediaDevices.ondevicechange !== 'undefined'
      && typeof navigator.mediaDevices.addEventListener !== 'undefined';
  }

  async getConfiguration() {
    if (!this.configuration && this.token) {
      this.configuration = await this.webRtcService.getConfiguration(this.token);
    }
  }

  async getConnectedDevices() {
    const videoConstraints: MediaTrackConstraints = { deviceId: null };
    const audioConstraints: MediaTrackConstraints = { deviceId: null };
    //await navigator.mediaDevices.getUserMedia({ audio: audioConstraints, video: videoConstraints })
    //  .then((stream) => {
    //    console.log("getConnectedDevices stream", stream.id);
    //    console.log("getConnectedDevices stream", stream);
    //    stream.getTracks().forEach(track => {
    //      console.log('getConnectedDevices track', track);
    //    });
    //    LocalUserStream.stopStream(stream, this.logService, true);
    //    stream = null;
    //    this.readAllDevices();
    //  })
    //  .catch(error => {
    //    console.log("getUserMedia video", error);
    //    this.logService.logSync(LogLevel.ERROR, 'getUserMedia video', error)
    //      .then(id => console.log('getUserMedia video', error));
    //    if (error.name !== 'NotAllowedError') {
    //      this.readAllDevices();
    //    }
    //  });

    this.readAllDevices();
  }
//LogInConferenceVariable
  readAllDevices() {
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        const listVideo = devices.filter(device => device.kind === 'videoinput');
        this.lists['videoinput'] = listVideo;
        this.updateSelect('select#availableCameras', listVideo, this.camera_deviceId.value);
        this.logService.logSync(LogLevel.DEBUG, 'enumerateDevices video', JSON.stringify(listVideo))
          .then(id => this.LogInConferenceVariable('enumerateDevices video', listVideo));

        const listAudioInput = devices.filter(device => device.kind === 'audioinput');
        this.lists['audioinput'] = listAudioInput;
        this.updateSelect('select#availableMicrophones', listAudioInput, this.microphone_deviceId.value);
        this.logService.logSync(LogLevel.DEBUG, 'enumerateDevices audioinput', JSON.stringify(listAudioInput))
          .then(id => this.LogInConferenceVariable('enumerateDevices audioinput', listAudioInput));

        const listAudioOutput = devices.filter(device => device.kind === 'audiooutput');
        this.lists['audiooutput'] = listAudioOutput;
        this.updateSelect('select#availableSpeakers', listAudioOutput, this.speaker_deviceId.value);
        this.logService.logSync(LogLevel.DEBUG, 'enumerateDevices audiooutput', JSON.stringify(listAudioOutput))
          .then(id => this.LogInConferenceVariable('enumerateDevices audiooutput', listAudioOutput));
        this.presetSettings();
      })
      .catch(error => {
        console.log("enumerateDevices", error);
        this.logService.logSync(LogLevel.ERROR, 'enumerateDevices video', error)
          .then(id => this.LogInConferenceVariable('enumerateDevices video', error));
      });
  }

  updateSelect(selector: string, deviceList: MediaDeviceInfo[], selectedDeviceId: any) {
    const listElement = document.querySelector(selector) as HTMLSelectElement;
    if (listElement && deviceList.length) {
      listElement.innerHTML = '';
      deviceList.forEach(device => {
        const listOption = document.createElement('option') as HTMLOptionElement;
        listOption.label = device.label;
        listOption.text = device.label;
        listOption.value = device.deviceId;
        listOption.selected = listOption.value == selectedDeviceId;
        listElement.add(listOption);
      });
    }
  }

  presetSettings() {
    this.camera_backgroundBlur.setValue((this.storageService.getItem(CAMERA_BACKGORUND_BLUR_SETTING) ?? 'false').toLowerCase() == 'true');

    if (this.localUserStream) {
      // camera
      const video = this.localUserStream.originalVideoTrack?.value;
      if (video) {
        //const savedSetting = JSON.parse(storageService.getItem(VIDEOSETTINGS)) as MediaTrackSettings;
        const setting = /*savedSetting ?? */video.getSettings();
        this.camera_deviceId.setValue(!this.lists['videoinput'] ? '' : setting.deviceId && (this.lists['videoinput'] as MediaDeviceInfo[]).some(i => i.deviceId == setting.deviceId) ? setting.deviceId : '');
        console.log('this.camera_deviceId.setValue 1', this.camera_deviceId.value);
      }

      // microphone
      const audio = this.localUserStream.audioTrack?.value;
      if (audio) {
        //const savedSetting = JSON.parse(this.storageService.getItem(AUDIOSETTINGS)) as MediaTrackSettings;
        const setting = /*savedSetting ?? */audio.getSettings();
        this.microphone_deviceId.setValue(!this.lists['audioinput'] ? '' : setting.deviceId && (this.lists['audioinput'] as MediaDeviceInfo[]).some(i => i.deviceId == setting.deviceId) ? setting.deviceId : '');
        this.microphone_autoGainControl.setValue(setting.autoGainControl ?? false);
        this.microphone_echoCancellation.setValue(setting.echoCancellation ?? true);
        this.microphone_noiseSupression.setValue(setting.noiseSuppression ?? false);
      }

      // speaker
      this.hasSinkId = ('sinkId' in HTMLMediaElement.prototype); // wenn der Browser sinkId kennt, dann kann man auch die Lautsprecher wählen
      //const audiooutput = document.querySelectorAll('div#remoteVideos audio'); // alle remote Videosounds sollen auf dem gewählten Gerät ausgegeben werden
      //if (audiooutput.length) {
      //const aud = audiooutput[0] as HTMLMediaElement;
      if (this.hasSinkId /*&& aud*/) {
        const savedSetting = this.storageService.getItem(SPEAKERSETTINGS);
        this.speaker_deviceId.setValue(!this.lists['audiooutput'] ? '' : savedSetting && (this.lists['audiooutput'] as MediaDeviceInfo[]).some(i => i.deviceId == savedSetting) ? savedSetting : '');
      }

      //this.localUserStream.setVirtualBackground(this.camera_backgroundBlur.value); 
      //}
    } else if (this.previewStream) {
      // console.log('presetSettings for previewStream');
      // camera
      const video = getVideoTrackFromStream(this.previewStream);
      if (video) {
        //const savedSetting = JSON.parse(this.storageService.getItem(VIDEOSETTINGS)) as MediaTrackSettings;
        const setting = /*savedSetting ?? */video.getSettings();
        // console.log('video setting', setting);
        this.camera_deviceId.setValue(!this.lists['videoinput'] ? '' : setting.deviceId && (this.lists['videoinput'] as MediaDeviceInfo[]).some(i => i.deviceId == setting.deviceId) ? setting.deviceId : '');
        console.log('this.camera_deviceId.setValue 2', this.camera_deviceId.value);
      }

      // microphone
      const audio = getAudioTrackFromStream(this.previewStream);
      if (audio) {
        //const savedSetting = JSON.parse(this.storageService.getItem(AUDIOSETTINGS)) as MediaTrackSettings;
        const setting = /*savedSetting ?? */audio.getSettings();
        // console.log('audio setting', setting);
        this.microphone_deviceId.setValue(!this.lists['audioinput'] ? '' : setting.deviceId && (this.lists['audioinput'] as MediaDeviceInfo[]).some(i => i.deviceId == setting.deviceId) ? setting.deviceId : '');
        this.microphone_autoGainControl.setValue(setting.autoGainControl ?? false);
        this.microphone_echoCancellation.setValue(setting.echoCancellation ?? true);
        this.microphone_noiseSupression.setValue(setting.noiseSuppression ?? false);
      }

      // speaker
      this.hasSinkId = ('sinkId' in HTMLMediaElement.prototype); // wenn der Browser sinkId kennt, dann kann man auch die Lautsprecher wählen
      //const audiooutput = document.querySelectorAll('div#remoteVideos audio'); // alle remote Videosounds sollen auf dem gewählten Gerät ausgegeben werden
      //if (audiooutput.length) {
      //const aud = audiooutput[0] as HTMLMediaElement;
      if (this.hasSinkId /*&& aud*/) {
        const savedSetting = this.storageService.getItem(SPEAKERSETTINGS);
        this.speaker_deviceId.setValue(!this.lists['audiooutput'] ? '' : savedSetting && (this.lists['audiooutput'] as MediaDeviceInfo[]).some(i => i.deviceId == savedSetting) ? savedSetting : '');
      }
      //}
    } else {
      const videoSetting = JSON.parse(this.storageService.getItem(VIDEOSETTINGS)) as MediaTrackSettings;
      if (videoSetting) {
        this.camera_deviceId.setValue(!this.lists['videoinput'] ? '' : videoSetting.deviceId && (this.lists['videoinput'] as MediaDeviceInfo[]).some(i => i.deviceId == videoSetting.deviceId) ? videoSetting.deviceId : '');
        console.log('this.camera_deviceId.setValue 3', this.camera_deviceId.value);
      }

      const audioSetting = JSON.parse(this.storageService.getItem(AUDIOSETTINGS)) as MediaTrackSettings;
      if (audioSetting) {
        this.microphone_deviceId.setValue(!this.lists['audioinput'] ? '' : audioSetting.deviceId && (this.lists['audioinput'] as MediaDeviceInfo[]).some(i => i.deviceId == audioSetting.deviceId) ? audioSetting.deviceId : '');
        this.microphone_autoGainControl.setValue(audioSetting.autoGainControl ?? false);
        this.microphone_echoCancellation.setValue(audioSetting.echoCancellation ?? true);
        this.microphone_noiseSupression.setValue(audioSetting.noiseSuppression ?? false);
      }

      this.hasSinkId = ('sinkId' in HTMLMediaElement.prototype); // wenn der Browser sinkId kennt, dann kann man auch die Lautsprecher wählen
      if (this.hasSinkId) {
        const speakerSetting = this.storageService.getItem(SPEAKERSETTINGS);
        this.speaker_deviceId.setValue(!this.lists['audiooutput'] ? '' : speakerSetting && (this.lists['audiooutput'] as MediaDeviceInfo[]).some(i => i.deviceId == speakerSetting) ? speakerSetting : '');
      }
    }
  }

  async setSettings(event: Event | null) {
    event && event.preventDefault();
    this.media_message = [];

    if (this.localUserStream) {
      this.setSettingsWithLocalUserStream();
      this.localUserStream.setVirtualBackground(this.camera_backgroundBlur.value); 
    } else {
      this.setSettingsNoLocalUserStream();
    }

    if (this.closeButton) {
      this.hideMediaSettings(event);
    }
  }

  async setSettingsNoLocalUserStream() {
    if (this._previewStream) {
      try {
        // camera
        const videoTracks = this._previewStream.getVideoTracks();
        if (videoTracks && videoTracks.length) {
          const video = videoTracks[0];
          if (video && this.camera_deviceId?.value != '') {
            const constraints = video.getConstraints();
            if (constraints.deviceId != this.camera_deviceId.value) {
              constraints.deviceId = this.camera_deviceId.value;

              let currentStream = this._previewStream;
              // console.log("stopping video", video);
              video.stop();
              currentStream.removeTrack(video);

              // console.log("switching video to", this.camera_deviceId.value);

              const { stream, message } = await LocalUserStream.getUserMediaWithMessage(
                { video: getVideoTrackConstraints(this.configuration, this.camera_deviceId.value) },
                this.logService
              );
              if (message) {
                this.media_message.push(message);
              }
              const newVideoTrack = getVideoTrackFromStream(stream);
              if (newVideoTrack) {
                currentStream.addTrack(newVideoTrack);
                this.storageService.setItem(VIDEOSETTINGS, JSON.stringify(constraints));
              }
            }
          }
        }

        const audioTracks = this._previewStream.getAudioTracks();
        if (audioTracks && audioTracks.length) {
          const audio = audioTracks[0];
          if (audio) {
            const constraints = audio.getConstraints();
            if ((this.microphone_deviceId?.value != '' && constraints.deviceId != this.microphone_deviceId.value)
              || constraints.autoGainControl != this.microphone_autoGainControl.value
              || constraints.echoCancellation != this.microphone_echoCancellation.value
              || constraints.noiseSuppression != this.microphone_noiseSupression.value
            ) {
              constraints.deviceId = this.microphone_deviceId.value;
              constraints.autoGainControl = this.microphone_autoGainControl.value;
              constraints.echoCancellation = this.microphone_echoCancellation.value;
              constraints.noiseSuppression = this.microphone_noiseSupression.value;

              let currentStream = this._previewStream;
              // console.log("stopping audio", audio);
              audio.stop();
              currentStream.removeTrack(audio);

              // console.log("switching audio to", constraints.deviceId);

              const { stream, message } = await LocalUserStream.getUserMediaWithMessage({ audio: getAudioTrackConstraints(constraints, constraints.deviceId.toString()) }, this.logService);
              if (message) {
                this.media_message.push(message);
              }
              const newAudioTrack = getAudioTrackFromStream(stream);
              if (newAudioTrack) {
                currentStream.addTrack(newAudioTrack);
                this.storageService.setItem(AUDIOSETTINGS, JSON.stringify(constraints));
              }
            }
          }
        }

        // speaker
        const audiooutput = document.querySelectorAll('#conferenceStartPreviewVideo');
        if (this.hasSinkId && audiooutput.length) {
          audiooutput.forEach(a => {
            const aud = a as any;
            if (typeof aud.sinkId !== 'undefined' && this.speaker_deviceId.value && this.speaker_deviceId.value != '') {
              aud.setSinkId(this.speaker_deviceId.value)
                .then(() => {
                  this.storageService.setItem(SPEAKERSETTINGS, this.speaker_deviceId.value);
                })
                .catch(e => {
                  console.warn('Fehler beim Setzen der AudioOutput Contraints to ' + this.speaker_deviceId.value + ': ' + e);
                });
            } else {
              if (this.speaker_deviceId.value && this.speaker_deviceId.value != '') {
                this.storageService.setItem(SPEAKERSETTINGS, this.speaker_deviceId.value);
              }
              // console.log('aud', aud);
              // console.log('this.speaker_deviceId.value ', this.speaker_deviceId.value);
            }
          });
        } else {
          if (this.speaker_deviceId.value && this.speaker_deviceId.value != '') {
            this.storageService.setItem(SPEAKERSETTINGS, this.speaker_deviceId.value);
          }
          // console.log('this.hasSinkId', this.hasSinkId);
          // console.log('audiooutput.length', audiooutput.length);
        }

        this.notificationService.notifySuccess('Die Einstellungen wurden übernommen.');
      }
      catch (error) {
        console.warn('Bei setzen der Einstellungen ist ein Fehler aufgetreten: ' + error.name + ' - ' + error.message);
        this.notificationService.notifyError('Beim Übernehmen der Einstellungen ist ein Fehler aufgetreten.');
        await this.logService.logAsync(LogLevel.INFO, 'setSettings', error);
      }
    }
  }

  async setSettingsWithLocalUserStream() {
    try {
      // camera
      const video = this.localUserStream.originalVideoTrack?.value;
      if (video) {
        const constraints = video.getConstraints();
        if (constraints.deviceId != this.camera_deviceId.value) {
          constraints.deviceId = this.camera_deviceId.value;

          const currentStream = this.localUserStream?.originalStream;
          if (currentStream) {
            console.log("stopping video", video);
            video.stop();
            currentStream.removeTrack(video);

            console.log("switching video to", this.camera_deviceId.value);

            const { stream, message } = await LocalUserStream.getUserMediaWithMessage({ video: getVideoTrackConstraints(this.configuration, this.camera_deviceId.value) }, this.logService);
            if (message) {
              this.media_message.push(message);
            }

            const newVideoTrack = getVideoTrackFromStream(stream);
            if (newVideoTrack) {
              currentStream.addTrack(newVideoTrack);
              console.log("adding video", newVideoTrack);
              this.localUserStream.setVirtualBackground(this.localUserStream.backgorundBlur);
              this.localUserStream.originalVideoTrack.next(newVideoTrack);
              await this.publishNewTrack(this.localUserStream.stream, this.localUserStream.videoTrack.value, 'video');
              this.storageService.setItem(VIDEOSETTINGS, JSON.stringify(constraints));
            }
          }
        }
      }

      // microphone
      const audio = this.localUserStream.audioTrack?.value;
      if (audio) {
        const constraints = audio.getConstraints();
        if (constraints.deviceId != this.microphone_deviceId.value) {
          constraints.deviceId = this.microphone_deviceId.value;
          constraints.autoGainControl = this.microphone_autoGainControl.value;
          constraints.echoCancellation = this.microphone_echoCancellation.value;
          constraints.noiseSuppression = this.microphone_noiseSupression.value;

          //this.localUserStream.trySetAudio(audio.enabled);
          const currentStream = this.localUserStream?.stream;
          if (currentStream) {
            console.log("stopping audio", audio);
            audio.stop();
            currentStream.removeTrack(audio);

            console.log("switching audio to", constraints.deviceId);

            const { stream, message } = await LocalUserStream.getUserMediaWithMessage({ audio: getAudioTrackConstraints(constraints, constraints.deviceId.toString()) }, this.logService);
            if (message) {
              this.media_message.push(message);
            }
            const newAudioTrack = getAudioTrackFromStream(stream);
            if (newAudioTrack) {
              currentStream.addTrack(newAudioTrack);
              await this.publishNewTrack(currentStream, newAudioTrack, 'audio');
              this.storageService.setItem(AUDIOSETTINGS, JSON.stringify(constraints));
              this.localUserStream.audioTrack.next(newAudioTrack);
            }
          }
        }
      }

      // speaker
      const audiooutput = document.querySelectorAll('div#remoteVideos audio');
      if (this.hasSinkId && audiooutput.length) {
        audiooutput.forEach(a => {
          const aud = a as any;
          if (typeof aud.sinkId !== 'undefined' && this.speaker_deviceId.value && this.speaker_deviceId.value != '') {
            aud.setSinkId(this.speaker_deviceId.value)
              .then(() => {
                this.storageService.setItem(SPEAKERSETTINGS, this.speaker_deviceId.value);
              })
              .catch(e => {
                console.warn('Fehler beim Setzen der AudioOutput Contraints to ' + this.speaker_deviceId.value + ': ' + e);
              });
          } else {
            // console.log('aud', aud);
            // console.log('this.speaker_deviceId.value ', this.speaker_deviceId.value);
          }
        });
      } else {
        if (this.speaker_deviceId.value && this.speaker_deviceId.value != '') {
          this.storageService.setItem(SPEAKERSETTINGS, this.speaker_deviceId.value);
        }
        // console.log('this.hasSinkId', this.hasSinkId);
        // console.log('audiooutput.length', audiooutput.length);
      }

      this.storageService.setItem(CAMERA_BACKGORUND_BLUR_SETTING, this.camera_backgroundBlur.value);

      this.notificationService.notifySuccess('Die Einstellungen wurden übernommen.');
    }
    catch (error) {
      console.log('Bei setzen der Einstellungen ist ein Fehler aufgetreten: ' + error.name + ' - ' + error.message);
      this.notificationService.notifyError('Beim Übernehmen der Einstellungen ist ein Fehler aufgetreten.');
      await this.logService.logAsync(LogLevel.INFO, 'setSettings', error);
    }
  }

  async publishNewTrack(stream: MediaStream, track: MediaStreamTrack, type: 'video' | 'audio') {
    if (this.connection instanceof OfferingStreamerConnection) {
      if (type == 'video') {
        await addOrReplaceTrack((this.connection as OfferingStreamerConnection).peerConnection, stream, 'video', track);
      }
      else {
        await addOrReplaceTrack((this.connection as OfferingStreamerConnection).peerConnection, stream, 'audio', track);
      }
    }
    else {
      (this.connection as AnsweringStreamerConnectionMultiplexer).connections.forEach(c => {
        if (type == 'video') {
          addOrReplaceTrack(c.peerConnection, stream, 'video', track);
        }
        else {
          addOrReplaceTrack(c.peerConnection, stream, 'audio', track);
        }
      })
    }
  }

  hideMediaSettings(event: Event) {
    event && event.preventDefault();
    (this.mediaSettings.nativeElement as HTMLDivElement).parentElement.parentElement.classList.remove("open");
  }

}